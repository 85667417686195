<!-- Social Share URLs - https://github.com/bradvin/social-share-urls -->

<template lang="pug">
  nav
    label Share
    div
      a.twitter(v-if="twitterUrl", :href="twitterUrl", target="_blank", rel="noopener")
      a.facebook(v-if="facebookUrl", :href="facebookUrl", target="_blank", rel="noopener")
      a.pinterest(v-if="pinterestUrl", :href="pinterestUrl",target="_blank", rel="noopener")
</template>

<script>
export default {
  name: 'ShareLinks',
  props: ['title', 'imgUrl'],
  data () {
    return {
      url: window.location.href
    }
  },
  watch: {
    '$route' () {
      this.url = window.location.href
    }
  },
  computed: {
    encodedTitle () {
      if (this.title) return encodeURIComponent(this.title)
      return encodeURIComponent(document.title)
    },
    twitterUrl () {
      if (this.url && this.encodedTitle) return 'https://twitter.com/intent/tweet?url=' + this.url + '&text=' + this.encodedTitle // &via={via}&hashtags={hashtags}`
      return false
    },
    facebookUrl () {
      if (this.url) return 'https://www.facebook.com/sharer.php?u=' + this.url
      return false
    },
    pinterestUrl () {
      if (this.url && this.imgUrl && this.encodedTitle) {
        return 'https://pinterest.com/pin/create/bookmarklet/?media=' + this.imgUrl + '&url=' + this.url + '&is_video=false&description=' + this.title
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin icon ($path) {
  background-image:url($path);
}
nav{
  display:flex;
  align-items: center;
  flex-wrap:wrap;
  label{
    margin-right:.5em;
  }
  div{
    display:flex;
    flex-wrap:nowrap;
    align-items: center;
    margin-right: -.25em;
  }
  a{
    font-size:1em;
    visibility:hidden;
    display: inline-block;
    padding:.25em;
    &:before{
      content:'';
      display:block;
      width:1.1em;
      height:1.1em;
      background-size:contain;
      background-position:center center;
      background-repeat:no-repeat;
    }
  }
  &:hover{
    a{
      visibility:visible;
    }
  }
}
.twitter:before{
  @include icon('../assets/icons/Twitter.svg');
}
.facebook:before{
  @include icon('../assets/icons/Facebook.svg');
}
.pinterest:before{
  @include icon('../assets/icons/Pinterest.svg');
}
</style>
