<template lang="pug">
  .product-images-carousel._relative
    div(ref="carousel")
      figure._w-full.carousel-cell(v-for="slide in slides")
        ._w-full._pb-ar-8x9._relative
          //- image / video poster
          resp-img(:bg="true", :image="slide.image", :lazy="false")
          //- (video)
          video._absolute._overlay._object-cover._object-center(v-if="slide.video", :data-src="slide.video", autoplay, playsinline, muted, loop, v-observe, @visible="onVideoVisible", @hidden="onVideoHidden")

    nav(v-show="slides.length > 1")
      //- prev button
      ._absolute._h-full._top-0._left-0._flex._items-center._pointer-events-none
        button._pl-5._pr-12._py-12._pointer-events-auto(@click="flkty.previous()")
          svg-chevron-down._transform._rotate-90._text-white
      //- next button
      ._absolute._h-full._top-0._right-0._flex._items-center._pointer-events-none
        button._pr-5._pl-12._py-12._pointer-events-auto(@click="flkty.next()")
          svg-chevron-down._transform._-rotate-90._text-white
</template>

<script>
import Flickity from 'flickity'
import RespImg from '@/components/RespImg'
import SvgChevronDown from '@/components/SvgChevronDown'
export default {
  name: 'ProductImagesCarousel',
  props: ['slides'],
  components: { RespImg, SvgChevronDown },
  data () {
    return {
      flkty: null
    }
  },
  methods: {
    init () {
      this.flkty = new Flickity(this.$refs.carousel, {
        // cellAlign: 'left',
        draggable: '>1',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: 0
        // autoPlay: 4000, // duration
        // pauseAutoPlayOnHover: false
      })
      // if (!this.visible) this.flkty.pausePlayer()
      // detect static clicks for routing
      // this.flkty.on('staticClick', () => { this.click = true })
      // resume autoplay after drag
      // this.flkty.on('dragEnd', () => this.flkty.playPlayer())
    },
    onVideoVisible (e) {
      const video = e.target
      // lazy load video
      if (!video.getAttribute('src')) {
        video.setAttribute('src', video.getAttribute('data-src'))
      }
      // play
      return video.paused && video.play()
    },
    onVideoHidden (e) {
      return !e.target.paused && e.target.pause()
    }
  },
  // watch: {
  //   slides () {
  //     console.log('slides updated', this.slides)
  //     setTimeout(() => {
  //       this.init()
  //       // this.flkty.reloadCells()
  //     }, 100)

  //   }
  // },
  mounted () {
    this.init()
  }
}
</script>

<style>
</style>
