<template lang="pug">
  .product-info-panel._fixed._z-30._top-0._right-0._h-full._w-full.md__w-1x3.lg__w-1x4.tw-borders._border-l._border-gray-300._transform._transition-transform._duration-500._bg-white._flex
    //- fills parent via .flex
    ._overflow-y-scroll._relative._w-full
      template(v-if="doc")
        rich-text.mt-lh._px-7._py-8.textbody._text-left.underline-links.lh-2._text-10.textbody(:field="doc.data.text")

      button._absolute._top-0._right-0._px-6._py-7(@click="close")
        icon(name="ticker-x-black")
    //- (close tap area mobile)
    //- button._absolute.lg__hidden._top-0._left-0._w-full._h-full._pointer-events-auto(@click="$emit('close')", aria-label="Close", style="cursor:e-resize", :class="{'_hidden': !uid}")
</template>

<script>
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
export default {
  name: 'ProductInfoPanel',
  components: { Icon, RichText },
  props: ['uid'], // 'page', 'richtext'],
  data () {
    return {
      doc: null
    }
  },
  watch: {
    uid () {
      this.get()
    }
  },
  methods: {
    async get () {
      this.doc = await this.$store.dispatch('getInfoPanel', this.uid)
    },
    close () {
      const query = { ...this.$route.query }
      delete query.info
      return this.$router.push({ query })
    }
  },
  created () {
    this.get()
  },
  mounted () {
    const appHeaderHt = document.getElementById('app-header').offsetHeight
    this.$el.style['padding-top'] = appHeaderHt + 'px'
  }
}
</script>

<style>
.product-info-panel-enter-active,
.product-info-panel-leave-active{
  transition:transform 500ms;
}
.product-info-panel-enter,
.product-info-panel-leave-to{
  transform: translateX(100%);
}
.product-info-panel{
  & h5 {
    font-size: 1.2em;
    margin-bottom: calc(18/12 * 1em);
  }
}
</style>
