<template lang="pug">
  .dialog-appointments._px-6.sm__px-0._z-50._fixed._overlay._flex(v-if="visible", @click.self="close")
    //- card
    ._m-auto._w-full.lg__w-1x2._bg-gray-100._gridff.sm__grid-cols-2.focus__outline-none(v-if="about", ref="card", tabindex="0")
      //- figure._relative._pb-ar-16x9.sm__pb-ar-3x4._bg-gray_200
        resp-img(:bg="true", :image="about.data.appointments_popup_image")
      div._flex._w-full.pad
        ._relative._w-full._flex._flex-col._justify-between
          rich-text._text-12._mb-20(:field="about.data.appointments_popup_text")

          router-link._w-full._block(to="/appointments")
            btn-row Get Started

          button._absolute._top-0._right-0(@click="close")
            icon(name="ticker-x-black")

</template>

<script>
import { mapState } from 'vuex'
import BtnRow from '@/components/BtnRow'
import RespImg from '@/components/RespImg'
import Icon from '@/components/Icon'
import RichText from '@/components/RichText'
export default {
  name: 'DialogAppointments',
  components: { RespImg, BtnRow, Icon, RichText },
  data () {
    return {
      visible: false,
      prevFocus: null
    }
  },
  computed: {
    ...mapState(['about'])
  },
  methods: {
    open () {
      this.visible = true
      this.prevFocus = document.activeElement
      this.$nextTick(() => {
        this.$refs.card.focus()
      })
    },
    close () {
      this.visible = false
      return this.prevFocus?.focus()
    }
  }
}
</script>

<style>
.dialog-appointments{
  /*&::backdrop{
    @apply bg-transparent
  }
  &[open]{
    @apply flex bg-transparent;
    max-width: none;
    max-height: none;
  }*/
  & .rich-text h6{
    font-size: calc(15/12 * 1em);
    @apply mb-4;
  }
}
</style>
