<template lang="pug">
  .product-subscriptions-selector(v-if="variantPlans.sellingPlans")
    ul._list-none
      li(v-for="plan in variantPlans.sellingPlans")
        button._block._w-full._p-8.hover__bg-gray-100._text-left(@click="addToCart(plan)") Subscribe: {{ plan.name }}
</template>

<script>

export default {
  name: 'ProductSubscriptionsSelector',
  props: ['product', 'variant'],
  data () {
    return {
      plans: []
    }
  },
  computed: {
    variantPlans () {
      return this.plans.find(plan => plan.variant === this.variant?.title) || {}
    }
  },
  methods: {
    async get () {
      try {
        let resp = await fetch(`https://${process.env.VUE_APP_SHOPIFY_DOMAIN}/products/${this.product.shopify_product.handle}`)
        resp = await resp.text()
        // console.log(resp)
        // parse data
        resp = findPlanJSON(resp)
          .replaceAll(',]', ']') // remove lazy commas at end of array items
        // parse into array
        this.plans = JSON.parse(resp)
      } catch (e) {
        console.error(e)
      }
    },
    addToCart (sellingPlan) {
      const variant = { ...this.variant }
      const id = sellingPlan.id + '_' + variant.id

      // convert ID to graphql format
      variant.id = btoa('gid://shopify/ProductVariant/' + this.variantPlans.variantID)

      this.$store.dispatch('addSubscriptionsToCart', [{
        id,
        variant,
        sellingPlan,
        quantity: 1,
        title: this.product.shopify_product.title,
        customAttributes: [{
          key: 'productType',
          value: this.product.shopify_product.productType
        }]
      }])
    }
  },
  created () {
    this.get()
  }
}

function findPlanJSON (str) {
  const regex = /<script id="productSellingPlanJSON">([^<)]*)/gm
  let m

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      // console.log(`Found match, group ${groupIndex}: ${match}`);
      str = match
    })
  }
  // console.log(str)
  return str
}
</script>

<style>
</style>
