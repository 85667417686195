<template lang="pug">
  ._h-20._flex._items-center._justify-center._text-btn._uppercase._relative(@click.self="$emit('click')", :class="[themeing]")
    slot
    //- info icon
    router-link._absolute._top-0._right-0._h-full._px-6._flex._items-center._pointer-events-auto(v-if="infoLink", :to="infoLink")
      svg-info-icon._block._h-12._-mr-2
</template>

<script>
import SvgInfoIcon from '@/components/SvgInfoIcon'
export default {
  name: 'BtnWithInfoIcon',
  components: { SvgInfoIcon },
  props: {
    infoLink: String,
    theme: { type: String, default: 'black' }
  },
  computed: {
    themeing () {
      const themes = {
        black: '_bg-black _text-white',
        white: '_bg-white _text-black'
      }
      return themes[this.theme]
    }
  }
}
</script>

<style>
</style>
