<template lang="pug">
  .slideshow(:class="{'slideshow--is-padless-on-mobile': isPadlessMobile}")
    transition-group(:name="transition")
      .slide(
        v-for="(slide, index) in responsiveSlides",
        :key="index",
        v-show="index == current",
        :class="{'slide--diptych': slide.sizing}",
        :data-diptych-sizing="slide.sizing")
          div.is-overlay
            template(v-if="loadImgs(index)")
              figure(v-for="(src, index) in slide.images", :class="{'is-overlay': fullBleed}")
                div(v-lazy:background-image="src", role="img", :class="{'background--cover is-overlay': fullBleed, 'background--contain': !fullBleed}", :style="{backgroundPosition: slide.bgpos}")
    nav(v-if="!autoplay && slides.length > 1", :data-arrows="arrowsPos")
      button.is-overlay.prev(@click="prev", aria-label="Previous Slide")
      button.is-overlay(@click="next", aria-label="Next Slide")
</template>

<script>
import Vue from 'vue'
import _throttle from 'lodash/throttle'
export default {
  name: 'Slideshow',
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'slide'
    },
    speed: {
      type: String,
      default: '1000'
    },
    arrowsPos: {
      type: String,
      default: ''
    },
    isPadlessMobile: {
      type: Boolean,
      default: false
    },
    fullBleed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      responsiveSlides: [],
      current: 0,
      interval: null,
      afterResize: null
    }
  },
  watch: {
    slides () {
      this.stop()
      this.current = 0
      this.setResponsiveSlides()
      this.play()
    }
  },
  methods: {
    setResponsiveSlides () {
      // Don't alter slides above mobile
      if (!Vue.$is('mobile')) {
        this.responsiveSlides = this.slides
        return
      }
      // If mobile:
      // break diptychs into individual slides
      const mobileSlides = []
      for (var i = 0; i < this.slides.length; i++) {
        const images = this.slides[i].images
        if (images) {
          const sizing = this.slides[i].sizing
          for (var s = 0; s < images.length; s++) {
            const slide = { images: [], sizing: sizing }
            slide.images.push(images[s])
            mobileSlides.push(slide)
          }
        }
      }
      this.responsiveSlides = mobileSlides
    },
    loadImgs (slideIndex) {
      // Only load imgs near the current slide
      // current
      if (this.current === slideIndex) return true
      // if at the end
      if (this.current === this.responsiveSlides.length - 1) return slideIndex === this.current - 1 || slideIndex === 0
      // if at beginning
      if (this.current === 0) return slideIndex === this.responsiveSlides.length - 1 || slideIndex === this.current + 1
      // in the middle
      return slideIndex === this.current - 1 || slideIndex === this.current + 1
    },
    next: _throttle(function () {
      this.current = this.current + 1 === this.responsiveSlides.length ? 0 : this.current + 1
    }, 100),
    prev: _throttle(function () {
      this.current = this.current - 1 < 0 ? this.responsiveSlides.length - 1 : this.current - 1
    }, 100),
    play () {
      if (this.autoplay && this.slides.length > 1) {
        this.interval = setInterval(() => {
          this.next()
        }, parseInt(this.speed))
      }
    },
    stop () {
      clearInterval(this.interval)
    },
    onResize: _throttle(function () {
      this.stop()
      clearTimeout(this.afterResize)
      this.afterResize = setTimeout(() => {
        this.setResponsiveSlides()
        this.play()
      }, 200)
    }, 100)
  },
  created () {
    window.addEventListener('resize', this.onResize)
  },
  mounted () {
    this.setResponsiveSlides()
    this.play()
  },
  destroyed () {
    this.stop()
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import '../style/variables';
$diptychPadY: $slideshowPad*1.75;
#app .slideshow{
  // position:relative;
  .slide{
    > div{
      display:flex;
      justify-content: space-between;
      align-items: stretch;
      background-color:$white;
      figure{
        width:100%;
        display:flex;
        align-items: stretch;
        .background--contain{
          width:100%;
        }
        .background--cover{
          background-size:cover;
          background-position: center;
        }
      }
    }
  }

  // padding
  .slide{
    > div{
      figure{
        padding:$slideshowPadY $slideshowPad
      }
    }
    &.slide--diptych{
      @include from($tablet) {
        // snap to gutter on tablet
        figure{
          &:first-child{
            justify-content: flex-end;
            .background--contain{
              background-position:right center;
            }
          }
          &:last-child{
            justify-content: flex-start;
            .background--contain{
              background-position:left center;
            }
          }
        }
        // sizing
        &[data-diptych-sizing="left < right"] figure:first-child,
        &[data-diptych-sizing="left > right"] figure:last-child{
          align-items: center;
          .background--contain{
            height:55vh; // (472/800)*100vh;
          }
        }
      }
    }
  }

  nav{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    button{
      width:50%;
      height:100%;
      background:transparent;
      padding:3rem;
      text-align:right;
      &:after{
        content:'';
        display:inline-block;
        color:inherit;
        width:3rem;
        height:3rem;
        padding:1rem;
        border-top-style: solid;
        border-right-style: solid;
        transform-origin:center;
        transform:rotate(45deg);
      }
      &.prev{
        text-align:left;
        &:after{
          transform:rotate(-135deg);
        }
      }
    }
    button:last-child{
      left:50%;
    }
    &[data-arrows="inset"] {
      button{
        padding:3rem (220/1280)*100vw;
        color:$secondary;
      }
    }
  }
}

@include mobile () {
  #app .slideshow {
    .slide {
      @media (orientation:portrait) {
        > div {
          // align-items: center;
          figure {
              padding:$slideshowPadY--portrait $slideshowPad--mobile;
              // height:100vh;
            }
        }
      }
      @media (orientation:landscape) {
        > div{
          figure{
            padding: $pad;
          }
        }
      }

      // diptychs -> single slides
      &.slide--diptych{
        figure{
          display: flex;
          position:absolute;
          top:0; left:0;
          width:100%; height:100%;
          transition: opacity 1s;
          opacity:1;
          &.diptych__figure--mobile-hidden{
            opacity:0;
          }
        }
        // size based on neighbor .slide--diptych
        // downsize:
        &[data-diptych-sizing="left < right"] figure,
        &[data-diptych-sizing="left > right"] + [data-diptych-sizing="left > right"] figure{
          align-items: stretch;
          .background--contain{
            background-size:75% auto;
          }
        }
        // undo downsize for "left < right" – right side
        &[data-diptych-sizing="left < right"] + [data-diptych-sizing="left < right"] figure{
          .background--contain{
            background-size:contain !important;
          }
        }
      }
    }

    &.slideshow--is-padless-on-mobile{
      .slide{
        > div{
          figure{
            padding:0;
          }
        }
      }
    }
  }
}
</style>
