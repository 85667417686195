<template lang="pug">
  article._min-h-screen
    //- xmp {{ variant }}
    //- hr
    //- h1 shopifyProducts
    //- xmp {{ $store.state.shopifyProducts }}
    //- hr
    //- ._py-4._px-4(v-if='!product.shopify_product')
      h1
        strong Error:
        | Please see that the Shopify product title in Prismic matches the actual title in Shopify.
      ._pt-4(v-if='product && product.data')
        | Shopify product title entered in Prismic:
        strong {{ product.data.shopify_product_title }}
      ._pt-4
        ._pb-4
          u List of available Shopify product titles
        li(v-for='p in $store.state.shopifyProducts') {{ p.title }}
    template(v-if="variant")
      //- two columns
      section.md__flex._items-start._relative._flex-row-reverse._bg-white.tw-borders
        //- (mobile first image)
        product-images-carousel.md__hidden._bg-gray-100(:slides="slides", :key="JSON.stringify(slides)")

        //- sticky text column
        section(:class="isSelectorPlans ? '_bg-gray-950' : '_bg-white'").md__w-1x3.lg__w-1x2.md__sticky._z-10._top-hdr-lg._left-0.md__h-tile-lnd.md__flex._text-left
          //- info column
          ._w-full.lg__w-1x2.md__flex.md__overflow-y-scroll.scrollbars-hidden.md__pt-10.md__pb-20
            //- main info
            .lg__overflow-x-hidden.scrollbars-hidden._m-auto
              ._w-full._flex._flex-col
                //- title, price,...
                header._px-6._py-7
                  ._flex._text-15
                    //- title
                    h1._flex-1
                      span(v-html="text(product, 'title') ", :class="isSelectorPlans && '_text-white'")
                      //- variant
                      //- span(v-html="variant.title")
                    //- price
                    //- div(v-if="Number(variant.price) > 0", v-html="price(variant.price)", :class="isSelectorPlans && '_text-white'")
                    div(v-if="Number(variant.price.amount) > 0", v-html="price(variant.price.amount)", :class="isSelectorPlans && '_text-white'")
                  //- flavor
                  div._mt-6._text-12(v-html="text(product, 'flavor').replace('& ', '&&nbsp;')", :class="isSelectorPlans && '_text-white'")

                //- HIDE SUBSCRIPTIONS
                //- (by appointment...)
                //- template(v-if="productPlans.length" )
                  //- by appointment toggle
                  label._h-20._flex._items-center._border-t.pad._cursor-pointer(:class="isSelectorPlans && '_text-white'")
                    input._mr-3(type="checkbox", v-model="isSelectorPlans", style="width:12px;height:12px" )
                    ._flex-1._text-10()
                      | By Appointment
                      template(v-if="maxDiscount > 0") `(Save Up To ${maxDiscount}%)` }}
                    button._p-3._-mr-6(@click="$refs.apptInfo.open()")
                      svg-info-icon._h-12._w-12

                  //- (dialog about appts)
                  dialog-appointments(ref="apptInfo")

                  //- plan selector
                  div(v-show="isSelectorPlans")
                    dropdown._text-12._text-white._bg-gray-850(ref="planSelector", :icon="productPlans.length > 1 ? 'chevron-inset' : 'none'", :class="{'pointer-events-none': productPlans.length <= 1}", style="border-top-color: currentColor")
                      //- active variant
                      ._h-20._flex._items-center._px-6._text-12(slot="summary")
                        span(v-if="activePlan") {{ activePlan.variant.title }} {{ activePlan.name }}

                      ul(v-if="productPlans.length > 1")
                        //- options...
                        button._h-20._flex._w-full._items-center._px-6._border-t._border-gray-300.hover__bg-gray-750(v-for="plan in productPlans", @click="changePlan(plan)")
                          | {{ plan.variant.title }} {{ plan.name }}
                          span._text-10._ml-4._mt-px(v-if="plan.discount") (Save {{ plan.discount }}%)

                    button._w-full(@click="addPlanToCart")
                      btn-row(theme='white') Add Subscription to Bag

                //- variant selector
                dropdown._text-12._bg-white(v-show="!isSelectorPlans", ref="variantSelector", :icon="variants.length > 1 ? 'chevron-inset' : 'none'", :class="{'pointer-events-none': variants.length <= 1}", style="border-top-color:black")
                  //- active variant
                  ._h-20._w-full._flex._items-center._px-6._text-12._relative(slot="summary")
                    | {{ variant.title }}
                    //- (in/out stock label, centered)
                    ._absolute._overlay._flex._items-center._justify-center._pl-12(:class="variant.available ? '_text-green' : '_text-red2'")
                      | {{ variant.available ? 'In Stock' : 'Out of Stock'}}

                  ul(v-if="variants.length > 1")
                    //- options...
                    button._h-20._flex._w-full._items-center._px-6._border-t._border-gray-300.hover__bg-gray-100(v-for="vrnt in variants", @click="changeVariant(vrnt)", :class="{'_text-gray-500': !vrnt.available}", :disabled="!vrnt.available", v-if='vrnt.title !== variant.title')
                      | {{vrnt.title}}

                //- add to bag
                button._w-full(v-show="!isSelectorPlans", :disabled="!variant.available", @click="variant && addToCart({variantId: variant.id, productType: product.shopify_product.productType, slug: $route.params.slug})" )
                  btn-row {{ !variant.available ? 'Not Available' : 'Add to Bag' }}

                //- description / founders note
                rich-text.textbody._p-6._text-11._tracking-wider._underline-links._children-mt-lh(:field="product.data.description", v-if="$prismic.asText(product.data.description).length", :class="isSelectorPlans && '_text-white'")

                //- dropdowns... (slice body)
                dropdown.underline-links._border-b._-mt-px(v-for="(slice, i) in product.data.dropdowns", v-if="slice.slice_type === 'dropdown'", icon="plus-inset", :class="isSelectorPlans && '_text-white'", :key='i')
                  //- header
                  prismic-rich-text._h-20._flex._items-center._px-6._text-12(slot="summary", :field="slice.primary.heading")
                  //- (body)
                  ._-mb-lh._bg-gray-50._px-6._py-7._text-11._tracking-wider(:class="isSelectorPlans ? '_bg-gray-900' : '_bg-white'")
                    //- description
                    rich-text.children-mt-lh._mb-lh.textbody(:field="slice.primary.description1", v-if="slice.primary.description1.length")
                    //- list
                    ul._flex._flex-wrap(v-if="slice.items.length")
                      //- items...
                      li._flex._items-start._w-1x2._mb-lh(v-for="item in slice.items", :class="{'_w-full': !slice.slice_label}")
                        //- icon (match width to .textbody indent)
                        ._flex._items-center._justify-start(style="min-height:1.25em; width: 1.8em;" )
                          icon(:name="item.icon", :class="!isSelectorPlans ? ' _text-black' : ' _text-white'")
                        //- text
                        rich-text._flex-1._hyphens-auto(:field="item.detail")

                //- info panels (slide out)
                transition(name="product-info-panel")
                  product-info-panel(v-if="$route.query.info", :uid="$route.query.info")

        //- (md: image scroll)
        section._hidden.md__block.md__w-2x3.lg__w-1x2
          //- slides...
          template(v-for="(slide, i) in slides")
            //- (video)
            template(v-if="slide.video")
              figure._w-full
                video._block._w-full(:src="slide.video", autoplay, muted, loop, playsinline, v-observe="0.1", @visible="e => e.target.paused && e.target.play()", @hidden="e => !e.target.paused && e.target.pause()")

            //- (image)
            template(v-else)
              figure._relative
                resp-img(:image="slide.image", :lazy="false", :key="slide.image.url")

      footer
        slice-body(:slices="product.data.footer")

</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Slideshow from '@/components/Slideshow'
import AppFooter from '@/components/AppFooter'
import ShareLinks from '@/components/ShareLinks'
import RespImg from '@/components/RespImg'
import ProductInfoPanel from '@/components/ProductInfoPanel'
import Dropdown from '@/components/Dropdown'
import kb from 'lodash/kebabCase'
import Icon from '@/components/Icon'
import ProductImagesCarousel from '@/components/Product/ProductImagesCarousel'
import SliceBody from '@/components/SliceBody'
import RichText from '@/components/RichText'
import ProductSubscriptionsSelector from '@/components/subscribe/ProductSubscriptionsSelector'
import SvgInfoIcon from '@/components/SvgInfoIcon'
import BtnRow from '@/components/BtnRow'
import { formatSubscriptionCartItem } from '@/store'
import DialogAppointments from '@/components/subscribe/DialogAppointments'

export default {
  name: 'Product',
  props: {
    slug: String
  },
  components: { RichText, ProductInfoPanel, BtnRow, RespImg, DialogAppointments, SvgInfoIcon, Slideshow, AppFooter, ShareLinks, Dropdown, Icon, ProductImagesCarousel, SliceBody, ProductSubscriptionsSelector },
  data () {
    return {
      kb,
      activeVariant: this.$route.query.variant,
      optionsVisible: false,
      panel: null,
      //
      infoVisible: false,
      text: Vue.$text,
      isSelectorPlans: false,
      activePlan: null
    }
  },
  computed: {
    ...mapState(['about', 'products']),
    ...mapGetters(['price']),
    product () {
      let doc = this.products.find(prd => prd.uid === this.slug)
      if (doc) {
        // add shopify product
        // console.log('product', doc)
        // console.log('title', doc.data.shopify_product_title)
        // console.log(this.$store.state.shopifyProducts)
        // this.$store.state.shopifyProducts.forEach(p => console.log(p.title))

        doc = {
          shopify_product: this.$store.state.shopifyProducts.find(prd => prd.title === doc.data.shopify_product_title) ? this.$store.state.shopifyProducts.find(prd => prd.title === doc.data.shopify_product_title) : false,
          ...doc
        }
        return doc
      } else {
        return false
      }
    },
    variants () {
      return this.product?.shopify_product?.variants || []
    },
    variant () {
      // The current variant of the linked shopify product
      const selected = this.variants.find(v => kb(v.title) === this.activeVariant)
      const firstAvail = this.variants.find(v => v.available)
      return selected || firstAvail || this.variants[0]
    },
    slides () {
      // Build slides data obj for Slideshow.vue
      let slides = []
      // imagesets as Prismic slices
      const slices = this.product?.data.variant_images || []
      // current variant imageset ?
      const slice = slices.find(slice => kb(slice.primary.shopify_variant_title) === kb(this.variant?.title))
      if (slice) {
        // slides...
        slides = slice.items.map(function (item) {
          const slide = {
            // images: [item.image.url],
            image: item.image,
            video: item.video?.url
          }
          return slide
        })
      }
      return slides
    },
    productPlans () {
      const handle = this.product?.shopify_product?.handle || -1
      const plansByVariant = this.$store.state.subscriptions[handle] || [] // organize by variant
      const plans = []
      // flatten into each plan with variant data
      plansByVariant.forEach(row => {
        row.sellingPlans.forEach(plan => {
          plans.push({
            ...plan,
            variant: { title: row.variant, id: row.variantID },
            discount: (plan.compare_at_price - plan.price) / plan.compare_at_price * 100
          })
        })
      })
      return plans
    },
    maxDiscount () {
      const plans = [...this.productPlans].sort((a, b) => a.discount > b.discount ? -1 : 0)
      return plans[0].discount
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.query.variant && to.query.variant !== this.activeVariant) {
        this.activeVariant = to.query.variant
      }
    },
    activeVariant (slug) {
      if (slug && slug !== this.$route.query.variant) {
        this.$router.replace({ name: 'shop__product', query: { variant: slug } })
      }
    },
    isSelectorPlans (active) {
      if (active && !this.activePlan) {
        this.activePlan = this.activePlan || this.productPlans.find(plan => plan.variantTitle === this.variant.title) || this.productPlans[0]
      }
    }
  },
  methods: {
    ...mapActions(['addToCart']),
    changeVariant (variant) {
      // replace route to change variant
      if (variant?.title) {
        this.activeVariant = kb(variant.title)
      }
      // close dropdown
      return this.$refs.variantSelector?.collapse()
    },
    changePlan (plan) {
      this.activePlan = plan
      this.changeVariant(plan.variant)
      // close dropdown
      return this.$refs.planSelector?.collapse()
    },
    updateMeta () {
      Vue.$meta.setTitle(Vue.$text(this.product, 'title'))
      Vue.$meta.setDescription(Vue.$text(this.product, 'meta_description', false))
    },
    togglePanel (text) {
      this.panel = this.panel === text ? null : text
    },
    addPlanToCart () {
      if (this.activePlan) {
        const item = formatSubscriptionCartItem(this.activePlan, this.variant, this.product.shopify_product)
        this.$store.dispatch('addSubscriptionsToCart', [item])
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    // redirect from old /item/:variantTitle path
    if (to.params.variantTitle) {
      return next({ name: 'shop__product', params: { slug: to.params.slug }, query: { variant: kb(to.params.variantTitle) } })
    }
    next()
  },
  async created () {
    await this.$store.dispatch('getProducts')

    // HIDE SUBSCRIPTIONS
    // get sub based on shop product handle (not slug...)
    // this.$store.dispatch('getProductSubscriptions', this.product.shopify_product.handle)
    // ^ potential timing issue on site entry if shopify products is slow...

    this.updateMeta()
    this.$store.commit('setBreadcrumb', [['Shop', '/shop'], [this.product.data.title[0].text, { ...this.$route }]])
  }
}
</script>
